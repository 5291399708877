import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { GlobalHelper } from '../../helper/global.helper';
import { PatientService } from '../../modules/patient/services/patient.service';
import { NoteService } from '../../modules/patient/notes/services/note.service';
import { VitalService } from '../../modules/patient/vitals/services/vital.service';
import { PatientCategoryService } from '../../modules/configuration/patient-categories/services/patient-category.service';
import { ShareModuleService } from '../../modules/patient/share-modules/services/share-module.service';
import { PatientRequestService } from '../../modules/patient/request-and-result/services';

import { PatientData, PatientModel, patientNavigationSelector, patientSelector } from 'src/app/modules/patient/store';

@Component({
    selector: 'app-inner-header',
    templateUrl: './inner-header.component.html',
    styleUrls: ['./inner-header.component.css'],
    providers: [
        PatientService,
        PatientCategoryService,
        VitalService,
        NoteService,
        ShareModuleService,
        PatientRequestService
    ]
})
export class InnerHeaderComponent implements OnInit, OnDestroy {

    isNavButtonDisabled = false;
    patientDatas;
    modalRef;
    opCategory;
    patientGUID;
    getPatSub;
    routerSub;
    storeWatcher = { patient: null, patientNavigation: null };
    croppedImage = '';
    nextLoading = false;
    prevLoading = false;
    showIPModal = false;
    allergyAccess = false;
    model: any = {};
    modalOptions: any = {};
    nextPrevPatientDatas: any = {};
    viewPatientAccess = false;
    isSeenForm = this.globalHelper.getPatModCurPath('header') === 'patient.change-appointment-status';
    catModalState: 		// State of the patient category modal:
        | 'updating'	// Disables the dropdown while update is in-progress.
        | 'loading' 	// Shows a spinner while loading the category data.
        | 'error' 		// Hides dropdown when there is error fetching category data.
        | null 			// Idle state.
        = null;

    constructor(private router: Router, private patientService: PatientService,  private viewContainerRef: ViewContainerRef,
        private patientCategoryService: PatientCategoryService, private globalHelper: GlobalHelper, private orgSettingService: ShareModuleService,
        private vitalService: VitalService, private noteService: NoteService, private modalService: NgbModal,
        private patientRequestService: PatientRequestService, private store: Store<{ data: PatientModel[] }>) {}

    ngOnInit() {
        this.goInit();
        const self = this;
        this.routerSub = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                self.isSeenForm = event.url.startsWith('/patient/change-appointment-status/');
            }
        });
        this.storeWatcher.patient = this.store.select(patientSelector).subscribe((results: any) => {
            // checking Patient haves any active Encounter
            if (results && results.haveActiveEncounter) {
                // checking patientData and result patient same are not
                if (self.patientDatas && self.patientDatas.patient_global_guid !== results.patient_global_guid) {
                    const encounterData = self.globalHelper.getCurData('enctr-dtl');
                    if (encounterData && encounterData.consultant_id) {
                        self.nextPreviousPatient(self.patientGUID, encounterData.consultant_id, encounterData.encounter_type);
                    } else {
                        self.nextPreviousPatient(self.patientGUID, results.consultant_id, results.patientEncounterType);
                    }
                }
            } else { // If patient have no active encounter then remove the next and prev buttons
                self.nextPrevPatientDatas = {};
            }
            // Updating the latest patient result with patientData, if next patient was changed this will update the patient details
            self.patientDatas = results;
        });
        this.storeWatcher.patientNavigation = this.store.select(patientNavigationSelector).subscribe((isDisabledPatientNavigation: boolean) => {
            self.isNavButtonDisabled = isDisabledPatientNavigation;
        });

        this.getMasterRecords();
    }

    goInit () {
        const self = this, appVars = this.globalHelper.getConstantsValue('app'),
        userAccessUrls = this.globalHelper.getCurData('user-access-urls');
        if (Array.isArray(userAccessUrls) && userAccessUrls.length) {
            this.allergyAccess = userAccessUrls.some(uAccData => uAccData.resource_url === 'patient/alerts-and-allergies');
            this.viewPatientAccess = userAccessUrls.some(uAccData => uAccData.resource_name === 'Details');
        }
        this.patientGUID = this.globalHelper.getParamsData();
        if (this.patientGUID) {
            if (this.getPatSub) {
                this.getPatSub.unsubscribe();
            }
            this.getPatSub = this.patientService.getPatientByGuid({ guid: this.patientGUID }).subscribe((result: any) => {
                self.globalHelper.lsPush(appVars.patientDetails, JSON.stringify(result));
                self.patientDatas = self.globalHelper.patientDetails = result;
                if (result.haveActiveEncounter) {
                    const encounterData = self.globalHelper.getCurData('enctr-dtl');
                    if (!encounterData.consultant_id) {
                        self.globalHelper.lsPush(self.globalHelper.getConstantsValue('app').encounterType, JSON.stringify({consultant_id: result.consultant_id, encounter_type: result.patientEncounterType}));
                        self.nextPreviousPatient(self.patientGUID, result.consultant_id, result.patientEncounterType);
                    } else {
                        self.nextPreviousPatient(self.patientGUID, encounterData.consultant_id, encounterData.encounter_type);
                    }
                } else {
                    self.nextPrevPatientDatas = {};
                }
            }, error => {
                self.nextLoading = self.prevLoading = false;
                self.router.navigate(['configuration']);
                setTimeout(() => {
                    self.globalHelper.toastrOpen('E', 'This patient is not found in the current branch');
                }, 150);
            }).add(() => self.getUnseenRecords(self.patientGUID));
        }
        this.store.dispatch(new PatientData({ guid: this.patientGUID }));
    }

    async getUnseenRecords (patGuid) {
        const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ patient_guid: patGuid });
        await this.noteService.getNotesUnseenCount({ params: paramsOptions }).subscribe((notesResults: any) => {
            self.globalHelper.updatePatDatas({note_unseen: notesResults.noteCount});
        }, (error) => {
            self.globalHelper.updatePatDatas({note_unseen: 0});
        });
        await this.vitalService.getVitalUnseenCount({ params: paramsOptions }).subscribe((vitalResults: any) => {
            self.globalHelper.updatePatDatas({vital_unseen: vitalResults.vitalCount});
        }, (error) => {
            self.globalHelper.updatePatDatas({vital_unseen: 0});
        });
        await this.patientRequestService.getRequestResultUnseenCount({ params: paramsOptions }).subscribe((requestResults: any) => {
            self.globalHelper.updatePatDatas({request_unseen: requestResults.requestCount});
        }, (error) => {
            self.globalHelper.updatePatDatas({request_unseen: 0});
        });
    }

    nextPreviousPatient (GUID, consultantId, encounterType) {
        const self = this;
        this.patientService.getPreviousNextPatient({ patient_guid: GUID, consultant_id: consultantId, encounter_type: encounterType }).subscribe((result: any) => {
            self.nextPrevPatientDatas = result;
            self.prevLoading = self.nextLoading = false;
        }, error => {
            self.prevLoading = self.nextLoading = false;
            self.router.navigate(['configuration']);
        });
    }

    changePatientPage(patGUID) {
        this.globalHelper.toastrClear();
        const self = this;
        this.globalHelper.changePatient(patGUID);
        window.scroll(0, 0);
        setTimeout(() => {
            self.goInit();
            self.getUnseenRecords(patGUID);
        });
    }

    openUploadModal () {
        this.globalHelper.toastrClear();
        this.modalOptions['modalDatas'] = {
            data: this.patientDatas.patient_picture
        };
        this.showIPModal = true;
    }

    modalCallback (img) {
        this.model.croppedImage = img;
        this.onSubmit();
    }

    rmCallback (patientDatas) {
        const self = this;
        this.patientService.deletePatientImg({patient_global_guid: this.patientDatas.patient_global_guid}).subscribe((result: any) => {
            patientDatas.patient_picture = null;
            self.globalHelper.updatePatDatas({patient_picture: null});
            self.globalHelper.toastrOpen('S', 'Patient Profile image removed successful!');
        });
    }

    openCatgChangeModal (catgDatas) {
        this.catModalState = null; // reset the cat-modal state
        this.globalHelper.toastrClear();
        const userAccessUrls = this.globalHelper.getCurData('user-access-urls');
        if (Array.isArray(userAccessUrls) && userAccessUrls.length &&
            userAccessUrls.some(uAccData => uAccData.resource_name === 'Update Patient')) {
            const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ status: 1 });

            // clear any prev modal and open the modal immediately
            if (this.modalRef) { this.modalRef.close(); }
            this.modalRef = this.modalService.open(catgDatas, { size: 'sm' });
            this.catModalState = 'loading'; // set the state of the modal as loading

            this.patientCategoryService.getModelLists({params: paramsOptions}).subscribe((results: any) => {
                this.catModalState = null; // reset the cat-modal state
                self.opCategory = results;
            }, () => {
                self.globalHelper.toastrOpen('E', 'Unable to load patient categories.');
                self.catModalState = 'error';
            });
        }
    }

    changeCategory(event) {
        this.globalHelper.toastrClear();
        this.catModalState = 'updating';

        const self = this, reqDatas = { patient_category_id: event };
        const localModalRef = this.modalRef;
        this.patientService.updateModel(this.patientDatas.patient_id, reqDatas).subscribe((results: any) => {
            // check validity of the local modal ref
            if (localModalRef === self.modalRef) { self.modalRef.close(); }

            self.globalHelper.toastrOpen('S', 'Patient category updated successful!');
            self.goInit();
        });
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }

    onSubmit() {
        this.globalHelper.toastrClear();
        const self = this, formData = new FormData();
        formData.append('img', this.model.croppedImage);
        formData.append('patient_guid', this.patientGUID);
        this.patientService.uploadPatientImg(formData).subscribe((results: any) => {
            self.globalHelper.toastrOpen('S', 'Patient image uploaded successful!');
            self.patientDatas.patient_picture = results.image;
            self.globalHelper.updatePatDatas({patient_picture: results.image});
            self.model.croppedImage = '';
        });
    }

    getMasterRecords() {
        const showBranchNameInTables = this.globalHelper.getConstantsValue('app').showBranchNameInTables;
        if (!localStorage.hasOwnProperty(showBranchNameInTables)) {
            const self = this;
            this.orgSettingService.getOrgSettings('get_branch_name_patient_grid').subscribe((result: any) => {
                self.globalHelper.lsPush(showBranchNameInTables, result);
            });
        }
    }

    ngOnDestroy () {
        if (this.storeWatcher.patient !== null) { this.storeWatcher.patient.unsubscribe(); }
        if (this.storeWatcher.patientNavigation !== null) { this.storeWatcher.patientNavigation.unsubscribe(); }
        if (this.routerSub) { this.routerSub.unsubscribe(); }
    }

}
