import { Injectable } from '@angular/core';

import { BaseService } from '../../../services/base/base.service';

@Injectable()
export class PharmacyService extends BaseService {
    public emailExportPath = '';

    getReportDatatableByEmail(bodyDatas?, options?) {
        return this.httpService.postMethod(`${this.emailExportPath}`, bodyDatas || {}, options);
    }

    getPrintHeader ({ showlogo, logo, gst_no, dl_nos, header, bar_img, saleHeader }) {
        const orgId = this.globalHelper.getCurData('curOrgId'),
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app');
        if (orgId === ajHospitalOrgId) {
            return [[{
                colSpan: 2,
                text: gst_no,
                fontSize: 7
            }, {}, {
                colSpan: 4,
                text: showlogo ? '21, P.S.R. Road, SIVAKASI - 626123' : '',
                fontSize: 10,
                italics: false
            }, {}, {}, {}, {
                text: dl_nos,
                fontSize: 7
            }]];
        }
        return [[{
            colSpan: 3,
            layout: 'noBorders',
            table: {
                body: [
                    logo,
                    [{
                        text: saleHeader,
                        fontSize: 9,
                        style: 'h2',
                        margin: [0, -5, 0, 0]
                    }]
                ]
            },
        }, {}, {}, {
            colSpan: 3,
            layout: 'noBorders',
            margin: [0, 7, 0, 0],
            table: {
                body: [
                    [{
                        text: 'Cash on Delivery : 7338877882',
                        fontSize: 8,
                        style: 'h2'
                    }], [{
                        text: `${gst_no}       ${dl_nos}`,
                        fontSize: 8
                    }]
                ]
            },
        }, {}, {}, {
            layout: 'noBorders',
            margin: [0, 6, 0, 0],
            table: {
                body: [
                    [{
                        text: header,
                        fontSize: 8,
                        bold: false,
                        margin: [0, -5, 0, -5],
                        alignment: 'center'
                    }],
                    bar_img
                ]
            }
        }]];
    }

    printSalePageContents(
        currentPageItems,
        hasNextPage,
        sale,
        saleReturnData,
        print_setting,
        orgId,
        ajHospitalOrgId,
        isSaleReturn,
        result_count,
        loop_count,
        index,
        cgst_total,
        sgst_total,
        total_item_amount,
        roundoff_amount,
        total_item_discount_amount) {

        const perPageInfo = [];
        const perPageItems = [];
        const patientAddress = sale.patient_address || sale.patientAddress;

        perPageItems.push([
                {
                    border: [false, true, false, false], rowspan: 2, text: 'S.No', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Product', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Batch', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Expiry', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Qty', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Price', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Taxable', style: 'th'
                },
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Discount', style: 'th'
                },
                {
                    border: [false, true, false, true], colSpan: 2, alignment: 'center', text: 'CGST', style: 'th'
                }, {},
                {
                    border: [false, true, false, true], colSpan: 2, alignment: 'center', text: 'SGST', style: 'th'
                }, {},
                {
                    border: [false, true, false, false], rowspan: 2, text: 'Total', style: 'th'
                }
            ], [
                { border: [false, false, false, true], text: '' },
                { border: [false, false, false, true], text: '' },
                { border: [false, false, false, true], text: '' },
                { border: [false, false, false, true], text: '' },
                { border: [false, false, false, true], text: '' },
                { border: [false, false, false, true], text: '' },
                { border: [false, false, false, true], margin: [0, -5, 0, -5], text: 'value' },
                { border: [false, false, false, true], margin: [0, -5, 0, -5], text: '%' },
                { border: [false, true, false, true], text: '%', fontSize: 7 },
                { border: [false, true, false, true], text: 'Amount', fontSize: 7 },
                { border: [false, true, false, true], text: '%', fontSize: 7 },
                { border: [false, false, false, true], text: 'Amount', fontSize: 7 },
                { border: [false, false, false, true], text: '' }
        ]);

        for (const item of currentPageItems) {
            // Update totals
            cgst_total += parseFloat(item.cgst_amount || 0);
            sgst_total += parseFloat(item.sgst_amount || 0);

            // Determine row styles
            let color = '#eeeeee', border = [false, false, false, false];

            // Add differenct color and border
            if (loop_count % 2 === 0) {
                color = '';
            }
            if (result_count === loop_count + 1) {
                border = [false, false, false, true];
            }

            // Row data
            const row = [
                { border, text: index, fillColor: color, style: 'td', alignment: 'left' },
                { border, text: item.prod.product_fullname || '-', fillColor: color, style: 'td' },
                { border, text: item.batch.batch_no || '-', fillColor: color, style: 'td' },
                { border, text: item.batch.expiry_date || '-', fillColor: color, style: 'td' },
                { border, text: item.quantity || '-', fillColor: color, style: 'td' },
                { border, text: item.mrp || '-', fillColor: color, style: 'td' },
                { border, text: item.taxable_value || '-', fillColor: color, style: 'td' },
                { border, text: item.discount_amount || '-', fillColor: color, style: 'td' },
                { border, text: item.cgst_percent || '-', fillColor: color, style: 'td' },
                { border, text: item.cgst_amount || '-', fillColor: color, style: 'td' },
                { border, text: item.sgst_percent || '-', fillColor: color, style: 'td' },
                { border, text: item.sgst_amount || '-', fillColor: color, style: 'td' },
                { border, text: item.total_amount || '-', fillColor: color, style: 'td', alignment: 'right' }
            ];
            perPageItems.push(row);
            index++;
            loop_count++;
        }

        // payment type
        const paymentTypes = { CA: 'Cash', CR: 'Credit', COD: 'Cash On Delivery' };
        const payment = paymentTypes[sale['payment_type']] || 'Unknown';

        // Retrieve barcode image
        const barCodeElemt = sale['Uhid'] ? document.getElementById(sale['Uhid']) : null;
        const bar_image_src = barCodeElemt ? barCodeElemt.querySelector('img').getAttribute('src') : null;

        let bar_img = [{ text: '' }] as any[];
        // Set barcode image if src is available
        if (bar_image_src) {
        bar_img = [{ image: 'sale_bar_image', height: 20, width: 100, alignment: 'right' }];
        }

        // Set barcode image in document definition if available for pdfMake content
        if (bar_image_src) {
        this.docDefinition.images['sale_bar_image'] = bar_image_src;
        }

        // Add logo if enabled
        let logo = [{ text: '' }] as any[];

        // Only add logo and address when showLogo is enabled
        if (print_setting.logo === 1) {
            logo = [{
                columns: [
                    {
                        image: `data:image/jpeg;base64,${print_setting.logoUrl}`,
                        height: 20,
                        width: 100,
                        margin: isSaleReturn ? [0, 8, 0, -3] : '',
                    },
                    {
                        text: `(${sale['branch_address']})`,
                        margin: isSaleReturn ? [0, 15, 0, 0] : [0, 5, 0, 0],
                        fontSize: 8
                    }
                ]
            }];
        }

        // Prepare header details
        const header = print_setting.title ? print_setting.title_text : '';
        const dl_nos = print_setting.dl_nos
            ? `${orgId === ajHospitalOrgId ? 'DL.NO.' : 'DL Nos. '}${print_setting.dl_no_text}`
            : '';
        const gst_no = print_setting.gst_no
            ? `${orgId === ajHospitalOrgId ? 'GSTIN' : 'GST No '}${print_setting.gst_no_text}`
            : '';

        // Add organization-specific details
        if (orgId === ajHospitalOrgId) {
            perPageInfo.push(
                { text: print_setting.logo === 1 ? 'ARUN KUMAR MEDICALS' : '', fontSize: 14, bold: true, alignment: 'center' },
                { text: print_setting.logo === 1 ? '(A Unit of GRAHAM CLINIC)' : '', fontSize: 12, alignment: 'center', italics: false },
                bar_img
            );
        }

        // Add table layout and header
        perPageInfo.push({
            layout: 'noBorders',
            table: {
                widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                body: this.getPrintHeader({
                    showlogo: print_setting.logo === 1,
                    logo,
                    gst_no,
                    dl_nos,
                    header: isSaleReturn ? header : '',
                    bar_img,
                    saleHeader: isSaleReturn ? '' : header
                })
            }
        });

        // Add totals row if processing the last loop iteration
        if (loop_count === result_count) {
            const totalRow = [
                {
                    border: [false, false, false, true],
                    colSpan: 8,
                    text: 'Total :',
                    alignment: 'right',
                    style: ['td', 'h2']
                },
                ...Array(7).fill({}),
                { border: [false, false, false, true], text: cgst_total.toFixed(2), style: ['td'] },
                { border: [false, false, false, true], text: cgst_total.toFixed(2), style: ['td'] },
                { border: [false, false, false, true], text: sgst_total.toFixed(2), style: ['td'] },
                { border: [false, false, false, true], text: sgst_total.toFixed(2), style: ['td'] },
                { border: [false, false, false, true], text: sale['total_item_sale_amount'], style: ['td'] }
            ];
            perPageItems.push(totalRow);
        }

        // Patient details
        const { patientTitle, patientName } = this.globalHelper.getPatientTitleAndName(sale['patient_name']);

        // Helper function to create table cells
        const createCell = (text, style = 'h2', customMargin = null, colSpan = 1) => ({
        border: [false, false, false, false],
        text,
        style,
        margin: customMargin || [0, 0, 0, 0],
        colSpan
        });

        const groupName = sale['patient_group_name'] ? [
            createCell('Group Name', 'h2', [-5, 0, 0, 0]),
            createCell(':'),
            createCell(sale['patient_group_name'], 'normaltxt')
        ] : [];

        // Create patient information table
        const patientInfoTable = {
        layout: 'Borders',
        table: {
            widths: ['auto', '*'],
            body: [
                [{
                    border: [false, true, false, false],
                    layout: { paddingLeft: () => 0, paddingRight: () => 2, paddingTop: () => 0, paddingBottom: () => 0 },
                    table: {
                        widths: [60, 5, 'auto'],
                        body: [
                            [
                                createCell('Date', 'h2', [-5, 0, 0, 0]),
                                createCell(':'),
                                createCell(this.globalHelper.getMomentDatas('app-date-time', sale['sale_date']), 'normaltxt')
                            ],
                            [
                                createCell('Patient', 'h2', [-5, 0, 0, 0]),
                                createCell(':'),
                                createCell(`${patientTitle}${patientName}`, 'normaltxt')
                            ],
                            ...groupName.length > 0 ? [groupName] : []
                        ]
                    }
                }, {
                    border: [false, true, false, false],
                    layout: { paddingLeft: () => 0, paddingRight: () => 2, paddingTop: () => 0, paddingBottom: () => 0 },
                    table: {
                        widths: ['auto', 5, 'auto', 35, 5, 'auto', 35, 5, 'auto'],
                        body: [
                            [
                                createCell('UHID'),
                                createCell(':'),
                                createCell(sale['Uhid'] || '-', 'normaltxt'),
                                createCell('Bill No'),
                                createCell(':'),
                                createCell(`${sale['bill_no']}/${payment}`, 'normaltxt'),
                                createCell('Doctor'),
                                createCell(':'),
                                createCell(sale['consultant_name'] || '-', 'normaltxt')
                            ],
                            [
                                createCell('Address'),
                                createCell(':'), {
                                    ...createCell(
                                        this.globalHelper.toTitleCase(
                                        (patientAddress && patientAddress.addr_perm_address)
                                            ? patientAddress.addr_perm_address.replace(/\n/ig, ' ')
                                            : ' - '
                                        ),
                                        'normaltxt'
                                    ),
                                    colSpan: 7
                                }
                            ]
                        ]
                    }
                }]
            ]
        }
        };



        // Add patient information table to `perPageInfo`
        perPageInfo.push(patientInfoTable, {
        layout: {
            hLineWidth: (idx) => (idx === 0 ? 3 : 1)
        },
        table: {
            headerRows: 1,
            widths: ['auto', '*', 50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: perPageItems
        }
        });

        // Add footer if there is no next page
        if (!saleReturnData) {
            perPageInfo.push({
                layout: 'noBorders',
                text: hasNextPage ? 'Please turn over' : '',
                bold: true,
                alignment: 'center',
                fontSize: 14,
            });
        }

        // Return updated data
        return {
        perPageInfo,
        loop_count,
        index,
        cgst_total,
        sgst_total,
        total_item_amount,
        roundoff_amount,
        total_item_discount_amount
        };

    }

    prepareSaleLastPage(sale, saleReturnData, cgst_total, sgst_total, orgId, ajHospitalOrgId) {
        const createRow = (label, value) => [
            { text: label, alignment: 'right', style: 'h2' },
            { text: ':', border: [false, false, false, false], style: 'h2', alignment: 'left' },
            { text: value, fontSize: 8, alignment: 'right' }
        ];

        const grandCalculation = [
            createRow('Round Off', sale['roundoff_amount'])
        ];

        if (sale['total_item_discount_amount'] !== '0.00') {
            grandCalculation.unshift(
                createRow('Total Amount', sale['total_item_sale_amount']),
                createRow('Discount', sale['total_item_discount_amount'])
            );
        }

        let sale_bar_img = [];

        const barCode = document.getElementById(sale['bill_no']);
        const isAhanaOrg = orgId === this.globalHelper.getConstantsValue('app').ahanaOrgId;
        const hasBillNo = sale['bill_no'] && document.contains(barCode);
        if (isAhanaOrg && hasBillNo) {
            const sale_bar_image = barCode.getElementsByTagName('img')[0].getAttribute('src');

            if (sale_bar_image) {
                sale_bar_img = [{
                    image: sale_bar_image,
                    height: 20,
                    width: 100,
                    alignment: 'right',
                    margin: [0, 0, 0, 0]
                }];
            } else {
                sale_bar_img = [{ text: '' }];
            }
        }

        const perPageInfoContent = [];

        const signContent: any = [
            [{
                text: 'Billed By',
                style: 'h2'
            }, {
                text: ':',
                style: 'h2'
            }, {
                text: sale['createdby_name'],
                style: 'normaltxt'
            }]
        ];
        if (orgId === ajHospitalOrgId) {
            signContent.push([{
                text: 'Medicine Given By',
                style: 'h2'
            }, {
                text: ':',
                style: 'h2'
            }, {
                text: '',
                style: 'normaltxt'
            }]);
            signContent.push([{
                text: 'Pharmacist Sign',
                style: 'h2',
                margin: [0, 10, 0, 0]
            }, {
                text: '',
                style: 'h2'
            }, {
                text: '',
                style: 'normaltxt'
            }]);
        }

        perPageInfoContent.push({
            border: [false, false, false, false],
            layout: {
                paddingLeft: () => 0,
                paddingRight: () => 0,
                paddingTop: () => 0,
                paddingBottom: () => 0,
                hLineWidth: () => 0
            },
            table: {
                widths: saleReturnData ? ['*', 210, 'auto'] : ['*', 120, '*'],
                body: saleReturnData ? [
                    [{
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            widths: ['*', 'auto', '*'],
                            body: [
                                [{
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    text: ''
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    text: ''
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        body: [sale_bar_img]
                                    }
                                }]
                            ]
                        }
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            alignmanet: 'right',
                            widths: ['auto', 'auto'],
                            body: [
                                [{
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'GST',
                                                alignmanet: 'right',
                                                style: 'h2'
                                            }, {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            }, {
                                                text: (cgst_total + sgst_total).toFixed(2),
                                                fontSize: 8,
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: grandCalculation
                                    }
                                }]
                            ]
                        },
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            alignmanet: 'right',
                            widths: ['auto'],
                            body: [
                               [{
                                    border: [false, false, false, false],
                                    colSpan: 1,
                                    layout: 'noBorders',
                                    table: {
                                        widths: [ '*', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'Total',
                                                fillColor: '#eeeeee',
                                                style: 'total',
                                                alignment: 'right',
                                                margin: [7, 0, 7, 0]
                                            }, {
                                                text: ':',
                                                fillColor: '#eeeeee',
                                                style: 'total'
                                            }, {
                                                text: 'INR ' + [sale['bill_amount']],
                                                fillColor: '#eeeeee',
                                                style: 'total',
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }]
                            ]
                        },
                    }],
                ] : [
                    [{
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            body: signContent
                        }
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            body: [
                                sale_bar_img
                            ]
                        }
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            alignmanet: 'right',
                            widths: ['auto', 'auto'],
                            body: [
                                [{
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'GST',
                                                alignmanet: 'right',
                                                style: 'h2'
                                            }, {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            }, {
                                                text: (cgst_total + sgst_total).toFixed(2),
                                                fontSize: 8,
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: grandCalculation
                                    }
                                }], [{
                                    border: [false, false, false, false],
                                    colSpan: 2,
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'Grand Total',
                                                fillColor: '#eeeeee',
                                                style: 'grandtotal',
                                                alignment: 'right'
                                            }, {
                                                text: ':',
                                                fillColor: '#eeeeee',
                                                style: 'grandtotal',
                                            }, {
                                                text: 'INR ' + [sale['bill_amount']],
                                                fillColor: '#eeeeee',
                                                style: 'grandtotal',
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }]
                            ]
                        },
                    }],
                ]
            }
        });
        return perPageInfoContent;
    }

    async getSalePrintContent (printDatas, fromSale, isSaleReturn?: any) {
        const content = [], sale = printDatas.saleReturnDatas ? printDatas.saleData : printDatas,
            orgId = this.globalHelper.getCurData('curOrgId'),
            saleReturnData = printDatas.saleReturnDatas && fromSale,
            ajHospitalOrgId = this.globalHelper.getConstantsValue('app').ajHospitalOrgId,
            print_setting = sale.salePrintSetting ? sale.salePrintSetting : {},
            result_count = Object.keys(sale.items).length,

            // We chunk the items here with 10 or less items per chunk
            // [[...], [...], [...]]
            groupedArr = this.createGroupedArray(sale.items, 10);
        let index = 1, loop_count = 0, cgst_total = 0, sgst_total = 0, total_item_amount, roundoff_amount, total_item_discount_amount;

        let currentPageIndex = 0;

        // Run until we have no chunks left (Will run until the groupedArr is empty)
        while (groupedArr.length) {
            // remove first two elements from the groupedArr
            const currentPageItems = groupedArr.shift();
            const nextPageItems = groupedArr.shift() || [];

            // we will try to fit the current page items onto page
            // if they don't fit, we remove one item from current page and add it to next page
            while (true) {
                const hasNextPage = nextPageItems.length > 0;
                const result = this.printSalePageContents(currentPageItems, hasNextPage, sale, saleReturnData, print_setting, orgId,
                    ajHospitalOrgId, isSaleReturn, result_count, loop_count, index, cgst_total, sgst_total, total_item_amount,
                    roundoff_amount, total_item_discount_amount);
                const tempContent = [result.perPageInfo];
                let lastPageContents;
                if (!hasNextPage) {
                    lastPageContents = this.prepareSaleLastPage(sale, saleReturnData, result.cgst_total, result.sgst_total, orgId, ajHospitalOrgId
                    );
                    tempContent.push(lastPageContents);
                }
                const tempDoc = {...this.docDefinition, content: tempContent};

                const pages = await this.getPDFPages(tempDoc);

                // This will check if the current items exceeds 1 page.
                // if it exceeds 1 page, then it will remove one item from current-items and move it to next page.
                // then it will rerun this loop and try to fit the currnt-page items to a single page again.
                if (pages.length > 1 && currentPageItems.length > 1 ) {
                    // remove last item and add it to next page items and try again
                    nextPageItems.unshift(currentPageItems.pop());
                } else {
                    // This will run if the current-items fit the page and does not exceed it. this will stop this loop from running.
                    loop_count = result.loop_count;
                    index = result.index;
                    cgst_total = result.cgst_total;
                    sgst_total = result.sgst_total;
                    total_item_amount = result.total_item_amount;
                    roundoff_amount = result.roundoff_amount;
                    total_item_discount_amount = result.total_item_discount_amount;

                    let tempContent2;
                    if (currentPageIndex !== 0 ) {
                         tempContent2 = [{
                            text: '',
                            pageBreak: 'before'
                        }, ...result.perPageInfo];

                         if (lastPageContents) {
                             tempContent2.push(lastPageContents);
                         }
                    } else {
                        tempContent2 = [ ...result.perPageInfo];
                        if (lastPageContents) {
                            tempContent2.push(lastPageContents);
                        }
                    }
                    content.push(tempContent2);

                    // we stop the `while (true)` loop from running here.
                    // the `while (groupedArr.length)` loop will continue to run until no items are left to be printed.
                    break;
                }
            }

            // if the next page items exists we add it back into the group
            if (nextPageItems.length) {
                groupedArr.unshift(nextPageItems);
            }

            currentPageIndex++;
        }


        return content;
    }

    preparePageContents(
        currentPageItems,
        hasNextPage,
        saleData,
        saleReturn,
        printDatas,
        orgId,
        ajHospitalOrgId,
        saleReturnOnly,
        return_item,
        result_count,
        loop_count,
        index,
        cgst_total,
        sgst_total,
        total_item_amount,
        roundoff_amount,
        total_item_discount_amount
    ) {
        const perPageInfo = [];
        const perPageItems = [];

        perPageItems.push(
            [
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'S.No',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Description',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Bill No',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'HSN Code',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Batch',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Expiry',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Qty',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Price',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Taxable value',
                    style: 'th'
                },
                {
                    border: [false, true, false, true],
                    colSpan: 2,
                    alignmanet: 'center',
                    text: 'CGST',
                    style: 'th'
                }, {},
                {
                    border: [false, true, false, true],
                    colSpan: 2,
                    alignmanet: 'center',
                    text: 'SGST',
                    style: 'th'
                }, {},
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Total',
                    style: 'th'
                },
            ],
            [
                {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                },
                {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, true, false, true],
                    text: 'Rate %',
                    fontSize: 5
                }, {
                    border: [false, true, false, true],
                    text: 'Amount',
                    fontSize: 5
                }, {
                    border: [false, true, false, true],
                    text: 'Rate %',
                    fontSize: 5
                }, {
                    border: [false, false, false, true],
                    text: 'Amount',
                    fontSize: 5
                }, {
                    border: [false, false, false, true],
                    text: ''
                },
            ]
        );

        for (const item of currentPageItems) {
            let particulars;
            const percentage = parseInt(item.discount_percentage);
            if (percentage > 0) {
                particulars = item['prod']['product_fullname'] + '(' + percentage.toString() + ')';
            } else {
                particulars = item['prod']['product_fullname'];
            }

            cgst_total += parseFloat(item.cgst_amount);
            sgst_total += parseFloat(item.sgst_amount);
            total_item_amount += parseFloat(item.total_amount);
            if (saleData) {
                roundoff_amount = Math.round(total_item_amount) - total_item_amount;
                total_item_discount_amount = printDatas.saleReturnDatas.reduce((sum: any, curr: any) => {
                    return sum + parseFloat(curr.total_item_discount_amount);
                }, 0).toFixed(2);
            }
            let color, border;
            if (loop_count % 2 === 0) {
                color = '';
            } else {
                color = '#eeeeee';
            }
            if (result_count === loop_count + 1) {
                border = [false, false, false, true];
            } else {
                border = [false, false, false, false];
            }
            perPageItems.push(
                [{
                border: border,
                text: index,
                fillColor: color,
                style: 'td',
                alignment: 'left'
            }, {
                border: border,
                text: particulars,
                fillColor: color,
                style: ['td']
            }, {
                border: border,
                text: saleData ? [item.bill_no || '-'] : '',
                fillColor: color,
                style: 'td'
            }, {
                border: border,
                text: [item.hsn_no || '-'],
                fillColor: color,
                style: 'td'
            },
                {
                    border: border,
                    text: item.batch.batch_no,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: item.batch.expiry_date,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: item.quantity,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: item.mrp,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [item.taxable_value || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [item.cgst_percent || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [item.cgst_amount || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [item.sgst_percent || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [item.sgst_amount || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: item.total_amount,
                    fillColor: color,
                    style: 'td',
                    alignment: 'right'
                },
            ]);
            index++;
            loop_count++;
        }

        if (!saleData) {
            perPageItems.map((items: any) => {
                items.splice(2, 1);
            });
        }

        let bar_image, bar_img, logo;
        const ahana_log = document.getElementById('sale_logo').getAttribute('src');
        const uhidElement = document.getElementById(saleReturn['uhid']);
        if (saleReturn['uhid'] && uhidElement && uhidElement.getElementsByTagName('img')[0]) {
            bar_image = uhidElement.getElementsByTagName('img')[0].getAttribute('src');
        }

        if (bar_image) { // Check Bar image is empty or not
            this.docDefinition.images['bar_image'] = bar_image;
            bar_img = [{
                image: 'bar_image',
                height: 20,
                width: 100,
                alignment: 'right'
            }];
        } else {
            bar_img = [{
                text: ''
            }];
        }
        // Image Loading End
        if (ahana_log && orgId !== ajHospitalOrgId) {
            this.docDefinition.images['logo'] = this.imgExport('sale_logo');

            logo = [{
                image: 'logo',
                height: 20,
                width: 100,
            }];
        } else {
            logo = [{
                text: ''
            }];
        }
        const header = saleData ? 'Sale Return' : 'Sale Return Bill';
        const dl_nos = 'DL Nos. : MDU/5263/20/21';
        const gst_no = 'GST No : 33AAQFA9991E1Z1';

        perPageInfo.push({
            layout: 'noBorders',
            table: {
                widths: [saleData ? 100 : 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                body: [
                    [{
                        layout: 'noBorders',
                        table: {
                            body: saleData && !saleReturnOnly ? [
                                [{}],
                                [ {
                                    text: header,
                                    fontSize: 9,
                                    style: 'h2'
                                }]
                            ] : [
                                logo,
                                [{
                                    text: gst_no,
                                    fontSize: 7
                                }],
                                [{
                                    text: saleReturn['branch_address'],
                                    fontSize: 9
                                }],
                            ]
                        },
                    }, {
                        layout: 'noBorders',
                        table: {
                            body: saleData && !saleReturnOnly ? [
                                [{}],
                                [{
                                    text: '',
                                    fontSize: 9,
                                    style: 'h2'
                                }]
                            ] : [
                                [{
                                    text: 'Sale Return Bill',
                                    style: 'h2',
                                    margin: [120, 0, 0, 0]
                                }]
                            ],
                        }
                    },
                        {},
                        {},
                        {},
                        {},
                        {
                            layout: 'noBorders',
                            table: {
                                body: saleData && !saleReturnOnly ? [
                                    [{}],
                                    [
                                        {
                                            border: [false, false, false, false],
                                            text: ['Payment Type : ' + return_item.payment_type],
                                            style: 'h2'
                                        }
                                    ],
                                ] : [
                                    [{
                                        text: dl_nos,
                                        fontSize: 7,
                                        alignment: 'right'
                                    }],
                                    [{
                                        text: 'Cash on Delivery : ' + [saleReturn['branch_phone']],
                                        fontSize: 9,
                                        alignment: 'right'
                                    }],
                                    bar_img
                                ]
                            },
                        },
                    ],
                ]
            },
        });
        const { patientTitle, patientName } = this.globalHelper.getPatientTitleAndName(saleReturn['patient_name']);
        perPageInfo.push(saleData && !saleReturnOnly ? {} : {
                layout: 'Borders',
                table: {
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        [{
                            border: [false, true, false, false],
                            colSpan: 6,
                            layout: {
                                paddingLeft: function (idx, node) {
                                    return 0;
                                },
                                paddingRight: function (idx, node) {
                                    return 2;
                                },
                                paddingTop: function (idx, node) {
                                    return 0;
                                },
                                paddingBottom: function (idx, node) {
                                    return 0;
                                },
                            },
                            table: {
                                body: [
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Patient',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                        {
                                            text: ':',
                                            border: [false, false, false, false],
                                            style: 'h2'
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: `${patientTitle}${patientName}`,
                                            style: 'normaltxt'
                                        }
                                    ],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'UHID',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                        {
                                            text: ':',
                                            border: [false, false, false, false],
                                            style: 'h2'
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: [saleReturn['uhid'] || '-'],
                                            style: 'normaltxt'
                                        }
                                    ],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Address',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                        {
                                            text: ':',
                                            border: [false, false, false, false],
                                            style: 'h2'
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: this.globalHelper.toTitleCase(saleReturn['patientAddress'] || '-'),
                                            style: 'normaltxt'
                                        }
                                    ],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Doctor',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                        {
                                            text: ':',
                                            border: [false, false, false, false],
                                            style: 'h2'
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: [saleReturn['consultant_name'] || '-'],
                                            style: 'normaltxt'
                                        }
                                    ],
                                ]
                            },
                        },
                            {}, {}, {}, {}, {},
                            {
                                border: [false, true, false, false],
                                layout: 'noBorders',
                                table: {
                                    body: [
                                        saleReturnOnly ? [{}, {}, {}] : [{
                                            border: [false, false, false, false],
                                            text: 'Bill No',
                                            style: 'h2',
                                            margin: [-7, 0, 0, 0],
                                        }, {
                                            text: ':',
                                            border: [false, false, false, false],
                                            style: 'h2'
                                        }, {
                                            border: [false, false, false, false],
                                            text: [saleReturn['bill_no'] + '/' + [saleReturn['salePaymentType']]],
                                            style: 'normaltxt'
                                        }], [{
                                            text: 'Date',
                                            style: 'h2',
                                            margin: [-7, 0, 0, 0],
                                        },  {
                                            text: ':',
                                            style: 'h2'
                                        }, {
                                            text: this.globalHelper.getMomentDatas('app-date-time', saleReturn['created_at']),
                                            style: 'normaltxt'
                                        }],
                                    ]
                                },
                            }
                        ],
                    ]
                },
            },
            {
                layout: {
                    hLineWidth: function (idx, node) {
                        return (idx === 0) ? 3 : 1;
                    }
                },
                table: {
                    headerRows: 1,
                    widths: saleData ? ['auto', '*', 'auto' , 'auto', 50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', 50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] ,
                    body: perPageItems,
                }
            });
        perPageInfo.push(saleData && !saleReturnOnly ? {} : {
            layout: 'noBorders',
            text: (!hasNextPage ? '' : 'To Be Continued'),
            bold: true,
            alignment: 'center',
            fontSize: 14,
        });

        return {
            perPageInfo,
            loop_count,
            index,
            cgst_total,
            sgst_total,
            total_item_amount,
            roundoff_amount,
            total_item_discount_amount
        };
    }

    prepareLastPage(
        saleData,
        saleReturn,
        printDatas,
        saleReturnOnly,
        return_item,
        cgst_total,
        sgst_total,
        total_item_amount,
        roundoff_amount,
        total_item_discount_amount
    ) {
        let grandDiscnt = [{}, {}, {}];
        if ((total_item_discount_amount !== '0.00' ) || (saleReturn['total_item_discount_amount'] && saleReturn['total_item_discount_amount'] !== '0.00')) {
            grandDiscnt = [{
                text: 'Discount',
                style: 'h2',
                alignment: 'right'
            },
                {
                    text: ':',
                    style: 'h2'
                },
                {
                    text: saleData ? total_item_discount_amount : saleReturn['total_item_discount_amount'],
                    alignment: 'right'
                }];
        }

        const perPageInfoContent = [];
        perPageInfoContent.push({
            layout: 'noBorders',
            table: {
                widths: ['*', 'auto', 'auto', '*', 'auto', '*', 'auto'],
                body: [
                    [
                        {
                            colSpan: 3,
                            layout: 'noBorders',
                            table: {
                                body: [
                                    [{
                                        text: 'Billed By',
                                        style: 'h2'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: saleData ? return_item.billed_by : saleReturn['createdby_name'],
                                            style: 'normaltxt'
                                        }]
                                ]
                            }
                        }, {}, {},
                        {
                            colSpan: 3,
                            layout: 'noBorders',
                            table: {
                                body: saleData && !saleReturnOnly ? [
                                    [
                                        {
                                            colSpan: 3,
                                            text: `GST: ${(cgst_total + sgst_total).toFixed(2)}`,
                                            style: 'h2'
                                        },
                                        {},
                                        {}],
                                ] : [
                                    [{
                                        text: 'CGST',
                                        style: 'h2'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: cgst_total.toFixed(2),
                                            style: 'normaltxt'
                                        }],
                                    [{
                                        text: 'SGST',
                                        style: 'h2'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: sgst_total.toFixed(2),
                                            style: 'normaltxt'
                                        }]
                                ]
                            },
                        },
                        {},
                        {},
                        {
                            layout: 'noBorders',
                            table: {
                                body: (saleData && !saleReturnOnly) ? [
                                    [{
                                        text: 'Total' ,
                                        fillColor: '#eeeeee',
                                        style: 'total',
                                        alignment: 'right'
                                    }, {
                                        text: ':' ,
                                        fillColor: '#eeeeee',
                                        style: 'total',
                                        alignment: 'right'
                                    }, {
                                        text: total_item_amount.toFixed(2) ,
                                        fillColor: '#eeeeee',
                                        style: 'total',
                                        alignment: 'right'
                                    }],
                                    [{}, {}, {}],
                                    [{
                                        text: 'Total Sale Value',
                                        style: 'h2',
                                        alignment: 'right',
                                        margin: [0, 1, 0, 0]
                                    },
                                        {
                                            text: ':',
                                            style: 'h2',
                                            margin: [0, 1, 0, 0]
                                        },
                                        {
                                            text: printDatas.saleData['bill_amount'],
                                            alignment: 'right'
                                        }],
                                    [{
                                        text: 'Sale Return Value',
                                        style: 'h2',
                                        alignment: 'right',
                                        margin: [0, 1, 0, 0]
                                    },
                                        {
                                            text: ':',
                                            style: 'h2',
                                            margin: [0, 1, 0, 0]
                                        },
                                        {
                                            text: total_item_amount.toFixed(2),
                                            alignment: 'right',
                                            margin: [0, 1, 0, 0]
                                        }],
                                    grandDiscnt,
                                    [{
                                        text: 'Round Off',
                                        style: 'h2',
                                        alignment: 'right'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: roundoff_amount.toFixed(2),
                                            alignment: 'right'
                                        }],
                                    [{
                                        text: 'Net Total',
                                        fillColor: '#eeeeee',
                                        style: 'grandtotal',
                                        // color: 'white'
                                    },
                                        {
                                            text: ':',
                                            fillColor: '#eeeeee',
                                            style: 'grandtotal',
                                            // color: 'white'
                                        },
                                        {
                                            text: 'INR ' + [(printDatas.saleData['bill_amount'] - Math.round(total_item_amount)).toFixed(2)],
                                            fillColor: '#eeeeee',
                                            style: 'grandtotal',
                                            // color: 'white'
                                        }]
                                ] : [
                                    [{
                                        text: 'GST',
                                        style: 'h2',
                                        alignment: 'right'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: (cgst_total + sgst_total).toFixed(2),
                                            alignment: 'right'
                                        }],
                                    [{
                                        text: 'Total Value',
                                        style: 'h2',
                                        alignment: 'right'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: saleReturnOnly ? total_item_amount.toFixed(2) : saleReturn['total_item_amount'],
                                            alignment: 'right'
                                        }],
                                    grandDiscnt,
                                    [{
                                        text: 'Round Off',
                                        style: 'h2',
                                        alignment: 'right'
                                    },
                                        {
                                            text: ':',
                                            style: 'h2'
                                        },
                                        {
                                            text: saleReturnOnly ? roundoff_amount.toFixed(2) : saleReturn['roundoff_amount'],
                                            alignment: 'right'
                                        }],
                                    [{
                                        text: 'Grand Total',
                                        fillColor: '#eeeeee',
                                        style: 'grandtotal',
                                        // color: 'white'
                                    },
                                        {
                                            text: ':',
                                            fillColor: '#eeeeee',
                                            style: 'grandtotal',
                                            // color: 'white'
                                        },
                                        {
                                            text: 'INR ' + [saleReturnOnly ? (total_item_amount + roundoff_amount).toFixed(2) : saleReturn['bill_amount']],
                                            fillColor: '#eeeeee',
                                            style: 'grandtotal',
                                            // color: 'white'
                                        }],
                                ]
                            },
                        }
                    ],
                ]
            },
        });

        return perPageInfoContent;
    }

    async getSaleReturnPrintContent (printDatas, fromSale) {
        const orgId = this.globalHelper.getCurData('curOrgId'),
            saleData = printDatas.saleData && fromSale,
            saleReturnOnly = this.globalHelper.getObjectValue(printDatas, 'saleData.saleReturnOnly', false),
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app');

        let return_item = {
            items: [],
            bill_no: [],
            billed_by: '',
            payment_type: 0,
            isDiscount: false
        };

        if (printDatas.saleData) {
            if (printDatas.saleReturnDatas.length) {
                for (let i = 0; i < printDatas.saleReturnDatas.length; i++) {
                    return_item = Object.assign(
                        return_item,
                        this.globalHelper.getObjectWithFieldOmit(printDatas.saleReturnDatas[i], 'items')
                    );
                    return_item.billed_by = printDatas.saleReturnDatas[i].createdby_name;
                    return_item.payment_type = printDatas.saleReturnDatas[i].salePaymentType;
                    return_item.isDiscount = printDatas.saleReturnDatas.some((discData: any) =>
                        discData.total_item_discount_amount !== '0.00');

                    printDatas.saleReturnDatas[i].items.map((return_data: any) => {
                        return_data['bill_no'] = printDatas.saleReturnDatas[i].bill_no;
                        return_item.items.push(return_data);
                    });
                }
            } else {
                return_item = printDatas.saleReturnDatas;
            }
        }
        const saleReturn = printDatas.saleData ? return_item : printDatas;
        const content = [];
        const result_count = printDatas.saleData ? return_item.items.length : Object.keys(saleReturn.items).length;
        let index = 1;
        let loop_count = 0;
        let cgst_total = 0;
        let sgst_total = 0;
        let total_item_amount = 0;
        let roundoff_amount = 0;
        let total_item_discount_amount: any = 0;

        // We chunk the items here.Each chunk having 20 items.
        // [[...], [...], [...]]
        const groupedArr = this.createGroupedArray(printDatas.saleData ? return_item.items : saleReturn.items, 20); // Changed Description rows
        let currentPageIndex = 0;

        // We run this loop until the groupedArr is empty. We remove chunk from it every loop.
        while (groupedArr.length) {
            // remove first two chunks
            const currentPageItems = groupedArr.shift();
            const nextPageItems = groupedArr.shift() || [];

            // Set page until all items fit the page
            // This loop will run until it fits the currentPageItems into a single page
            // It will continuously remove items from currentPageItems and add it to nextPageItem until its contents fits a single page.
            while (true) {
                const hasNextPage = nextPageItems.length > 0;

                const result = this.preparePageContents(
                    currentPageItems,
                    hasNextPage,
                    saleData,
                    saleReturn,
                    printDatas,
                    orgId,
                    ajHospitalOrgId,
                    saleReturnOnly,
                    return_item,
                    result_count,

                    loop_count,
                    index,
                    cgst_total,
                    sgst_total,
                    total_item_amount,
                    roundoff_amount,
                    total_item_discount_amount
                );

                const tempContent = [result.perPageInfo];
                let lastPageContents;
                if (!hasNextPage) {
                    lastPageContents = this.prepareLastPage(
                        saleData,
                        saleReturn,
                        printDatas,
                        saleReturnOnly,
                        return_item,
                        result.cgst_total,
                        result.sgst_total,
                        result.total_item_amount,
                        result.roundoff_amount,
                        result.total_item_discount_amount
                    );
                    tempContent.push(lastPageContents);
                }
                const tempDoc = {...this.docDefinition, content: tempContent};
                const pages = await this.getPDFPages(tempDoc);

                // Here we check if the page length exceeds 1.
                // If the length is greater than 1, then we remove an item from currentPageItems and add it to nextPageItems.
                if (pages.length > 1 && currentPageItems.length > 1 ) {
                    // remove last item and add it to next page items and try again
                    nextPageItems.unshift(currentPageItems.pop());
                } else {
                    // The currentPageItems fits the current page. Now we break away from the loop.
                    loop_count = result.loop_count;
                    index = result.index;
                    cgst_total = result.cgst_total;
                    sgst_total = result.sgst_total;
                    total_item_amount = result.total_item_amount;
                    roundoff_amount = result.roundoff_amount;
                    total_item_discount_amount = result.total_item_discount_amount;

                    let tempContent2;
                    if (currentPageIndex !== 0 ) {
                         tempContent2 = [{
                            text: '',
                            pageBreak: 'before'
                        }, ...result.perPageInfo];

                         if (lastPageContents) {
                             tempContent2.push(lastPageContents);
                         }
                    } else {
                        tempContent2 = [ ...result.perPageInfo];
                        if (lastPageContents) {
                            tempContent2.push(lastPageContents);
                        }
                    }
                    content.push(tempContent2);

                    // Here we break away from the `while (true)` loop.
                    // The `while (groupedArr.length)` will continue to run until all items have been printed.
                    break;
                }
            }

            // add back in nextpageitem if they exist
            if (nextPageItems.length) {
                groupedArr.unshift(nextPageItems);
            }

            currentPageIndex++;
        }

        return content;
    }

    imgExport (imgID) {
        const img: any = document.getElementById(imgID);
        const canvas = document.createElement('canvas');
        canvas.width = img['width'];
        canvas.height = img['height'];

        // Copy the image contents to the canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // Get the data-URL formatted image
        // Firefox supports PNG and JPEG. You could check img.src to
        // guess the original format, but be aware the using "image/jpg"
        // will re-encode the image.
        const dataURL = canvas.toDataURL('image/png');
        return dataURL;
    }

    createGroupedArray (arr = [], chunkSize) {
        const groups = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            groups.push(arr.slice(i, i + chunkSize));
        }
        return groups.length === 0 ? [[]] : groups;
    }
}

